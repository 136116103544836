
<!--suppress ALL -->
<template>
<div>
    <br>
    <h1 class="text-lg font-bold truncate mr-5"><i class="pi pi-list" style="fontSize: 0.9rem"></i> Lista de consultas SAP</h1>
    <br>
    <Calendar v-model="value" dateFormat="dd/mm/yy" :monthNavigator="true" :yearNavigator="true"/>
    <Accordion :multiple="true" :activeIdex="active" >
        <AccordionTab header="General">
            <ul >
                <li v-for="(gener, index) in acordeon.general" :key="index">
                    <a @click="openModal(gener.id, gener.name_query)"><i class="pi pi-angle-double-right" style="fontSize: 0.9rem"></i> {{ gener }}</a>
                    <br> <br>
                </li>
            </ul>
        </AccordionTab>
        <AccordionTab header="Operación">
            <ul >
                <li v-for="(opera, index) in acordeon.operacion" :key="index">
                    <a @click="openModal(opera.id, opera.name_query)"><i class="pi pi-angle-double-right" style="fontSize: 0.9rem"></i> {{ opera.name_query }} </a>
                    <br> <br>
                </li>
            </ul>
        </AccordionTab>
        <AccordionTab header="Informes">
            <ul >
                <li v-for="(infor, index) in acordeon.informes" :key="index">
                    <a @click="openModal(infor.id, infor.name_query)"><i class="pi pi-angle-double-right" style="fontSize: 0.9rem"></i> {{ infor.name_query }}</a>
                    <br> <br>
                </li>
            </ul>
        </AccordionTab>
        <AccordionTab header="Coordinación">
            <ul>
                <li v-for="(coord, index) in acordeon.coordinacion" :key="index">
                    <a @click="openModal(coord.id, coord.name_query)"><i class="pi pi-angle-double-right" style="fontSize: 0.9rem"></i> {{ coord.name_query }}</a>
                    <br> <br>
                </li>
            </ul>
        </AccordionTab>
        <AccordionTab header="Contabilidad">
            <ul >
                <li v-for="(contab, index) in acordeon.contabilidad" :key="index">
                    <a @click="openModal(contab.id, contab.name_query)"><i class="pi pi-angle-double-right" style="fontSize: 0.9rem"></i> {{ contab }}</a>
                    <br> <br>
                </li>
            </ul>
        </AccordionTab>
        <AccordionTab header="Gerencia">
            <ul >
                <li class="p-mb-3" v-for="(gerenc, index) in acordeon.gerencia" :key="index">
                  <a @click="openModal(gerenc.id, gerenc.name_query)"><i class="pi pi-arrow-right"></i> {{ gerenc.name_query }}</a>
                </li>
            </ul>
        </AccordionTab>
        <AccordionTab header="Dirección">
            <ul >
                <li v-for="(direcci, index) in acordeon.direccion" :key="index">
                  <a @click="openModal(direcci.id, direcci.name_query)"><i class="pi pi-angle-double-right" style="fontSize: 0.9rem"></i> {{ direcci.name_query }}</a>
                  <br> <br>
                </li>
            </ul>
        </AccordionTab>
    </Accordion>
</div>
<modal
  @update:visible="handleClose"
  ref="childComponentRef"
  @closemodal="closePosition()"
  v-model:visible="display"
  :style="{width: '100%', 'max-height': '100%'}"
  position="top"
  :modal="true"
  v-bind:jsonmodal="datosModal">
</modal>
</template>

<script>
import { onMounted, ref } from 'vue'
import Accordion from 'primevue/accordion'
import Calendar from 'primevue/calendar'
import AccordionTab from 'primevue/accordiontab'
import modal from './components/modals/modal'
import microsap from '../../services/FarmConsulFecha/links'
import microparams from '../../services/FarmConsulFecha/modalparams'
import micromxselect from '../../services/FarmConsulFecha/mxselect'
import micropricselect from '../../services/FarmConsulFecha/pricselect'
import microbodselect from '../../services/FarmConsulFecha/bodselect'
import micromuniselect from '../../services/FarmConsulFecha/muniselect'
import microdeptselect from '../../services/FarmConsulFecha/deptselect'

export default {
    components: {
        Accordion,
        AccordionTab,
        modal,
        Calendar
    },
  setup () {
    onMounted(() => {
      consultarintranet()
    })
    const active = ref(0)
    const value = ref(null)
    const display = ref(false)
    const childComponentRef = ref()
    const arrQueryparams = ref([])
    var mxselect = ref([])
    var pricselect = ref([])
    var muniselect = ref([])
    var deptselect = ref([])
    var modselect = ref([])
    var servselect = ref([])
    var ynselect = ref([])
    var bodselect = ref([])
    const acordeon = ref({
      general: [],
      operacion: [],
      informes: [],
      coordinacion: [],
      contabilidad: [],
      gerencia: [],
      direccion: []
    })
    var datosModal = { parameters: [{}], namequery: null }

    const consultarintranet = () => {
      microsap().then((response) => {
        for (const i in response.data) {
          if (response.data[i].categoria === 'OPERACIONES') {
            acordeon.value.operacion.push(response.data[i])
          } else if (response.data[i].categoria === 'COORDINACION') {
            acordeon.value.coordinacion.push(response.data[i])
          } else if (response.data[i].categoria === 'DIRECCION') {
            acordeon.value.direccion.push(response.data[i])
          } else if (response.data[i].categoria === 'GERENCIA') {
            acordeon.value.gerencia.push(response.data[i])
          } else if (response.data[i].categoria === 'INFORMES') {
            acordeon.value.informes.push(response.data[i])
          }
        }
      }).catch(err => {
        console.error(err.message)
      })
    }

    const openModal = (id, namequery) => {
      modalparams(id, namequery).then(() => {
        display.value = true
      })
    }

    async function getPriceList () {
      await micropricselect().then((response) => {
        pricselect.value = response.data
      }).catch(err => {
        console.error(err.message)
      })
    }

    async function getDepartamento () {
      await microdeptselect().then((response) => {
        deptselect.value = response.data
      }).catch(err => {
        console.error(err.message)
      })
    }

    async function getMunicipio () {
      await micromuniselect().then((response) => {
        muniselect.value = response.data
      }).catch(err => {
        console.error(err.message)
      })
    }

    async function getnameBodega () {
      await microbodselect().then((response) => {
        bodselect.value = response.data
      }).catch(err => {
        console.error(err.message)
      })
    }

    async function getnameconsumomxcliente () {
      await micromxselect().then((response) => {
        mxselect.value = response.data
      }).catch(err => {
        console.error(err.message)
      })
    }

    function getnameModalidadContrato () {
      modselect.value = [{ nombre: 'CAPITACION' }, { nombre: 'EVENTO' }]
    }

    function getnameServicio () {
      servselect.value = [{ nombre: 'POS' }, { nombre: 'NO POS' }]
    }

    function getOptionYesNo () {
      ynselect.value = [{ nombre: 'SI' }, { nombre: 'NO' }]
    }

    function closePosition () {
      this.display = false
      // childComponentRef.value.doSomething()
    }

    async function modalparams (id, namequery) {
      await microparams(id).then(async (response) => {
        datosModal.parameters = response.data.slice()
        datosModal.namequery = namequery
        await cargarselects(datosModal.parameters)
        datosModal.parameters.consumomxcliente = mxselect.value
        datosModal.parameters.modselect = modselect.value
        datosModal.parameters.pricselect = pricselect.value
        datosModal.parameters.bodselect = bodselect.value
        datosModal.parameters.ynselect = ynselect.value
        datosModal.parameters.muniselect = muniselect.value
        datosModal.parameters.deptselect = deptselect.value
        datosModal.parameters.servselect = servselect.value
      }).catch(err => {
        console.error(err.message)
      })
    }

    async function cargarselects (parametros) {
      await selects(parametros)
    }

    async function selects (parametros) {
      for (var i in parametros) {
        if (parametros[i].type === 'select') {
          var json = JSON.parse(parametros[i].data)
          if (json.options === 'get_name_consumo_mx_cliente') {
            await getnameconsumomxcliente()
          } else if (json.options === 'get_price_list') {
            await getPriceList()
          } else if (json.options === 'get_option_yes_no') {
            await getOptionYesNo()
          } else if (json.options === 'get_name_modalidad_contrato') {
            await getnameModalidadContrato()
          } else if (json.options === 'get_municipio') {
            await getMunicipio()
          } else if (json.options === 'get_name_bodega') {
            await getnameBodega()
          } else if (json.options === 'get_departamento') {
            await getDepartamento()
          } else if (json.options === 'get_name_servicio') {
            await getnameServicio()
          }
        }
      }
    }

    function handleClose () {
      childComponentRef.value.doSomething()
      datosModal.parameters = []
    }

    return {
      active,
      acordeon,
      openModal,
      closePosition,
      display,
      datosModal,
      arrQueryparams,
      modalparams,
      childComponentRef,
      handleClose,
      cargarselects,
      selects,
      getDepartamento,
      getMunicipio,
      getnameBodega,
      getnameconsumomxcliente,
      getnameModalidadContrato,
      getnameServicio,
      getOptionYesNo,
      getPriceList,
      modselect,
      ynselect,
      servselect,
      pricselect,
      bodselect,
      muniselect,
      deptselect,
      mxselect,
      value
    }
  }
}
</script>

<style scoped lang="scss">
a:hover {
  // background: green;
  font-size: 16.3px;
  color: #14286d;
  cursor: pointer;
}

a {
  color: #1c3faa;
  font-weight: 400;
}
</style>
