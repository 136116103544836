<template>
  <Dialog :header="'>> Consulta: ' + jsonmodal.namequery" :dismissableMask="true">
    <form action class="form" @submit.prevent="contact(jsonmodal.namequery)">
      <div class="p-fluid">
        <div class="p-field" v-for="(data, index) in jsonmodal.parameters" :key="index">
          <!-- Se valida input tipo date -->
          <template v-if="data.type === 'date'">
            <label :for="data.name_input[index]">{{ data.label }}</label>
            <!-- <Calendar v-model="parametros2[data.num_parametro]" dateFormat="dd/mm/yy" :showButtonBar="true"/> -->
           <input type="date" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" v-model="parametros2[data.num_parametro]">
          </template>
        </div>
      </div>
      <div v-for="(i, index) in jsonmodal.parameters" :key="index">
        <!-- Valida input tipo select-->
        <div class="p-field" v-if="i.type=='select'">
          <div class="p-grid">
            <div class="p-col-2">
              <label :for=" i.data[index] "><strong>{{ i.label }}:</strong></label>
            </div>
            <div class="p-col-1">
            </div>
            <div class="p-col-9">
              <div id="v-model-select" class="demo" v-if="JSON.parse(i.data).options=='get_price_list'">
                <select v-model="parametros2[i.num_parametro]"
                        :style="{ border: '1px solid black', 'border-radius': '5px' }" required>
                  <option v-for="(j, indexj) in jsonmodal.parameters.pricselect" :key="indexj">{{ j.ListName }}</option>
                </select>
              </div>
              <div id="v-model-select" class="demo"
                   v-else-if="JSON.parse(i.data).options=='get_name_consumo_mx_cliente'">
                <select v-model="parametros2[i.num_parametro]"
                        :style="{ border: '1px solid black', 'border-radius': '5px' }" required>
                  <option v-for="(j, indexj) in jsonmodal.parameters.consumomxcliente" :key="indexj">{{
                      j.cliente
                    }}
                  </option>
                </select>
              </div>
              <div id="v-model-select" class="demo" v-if="JSON.parse(i.data).options=='get_option_yes_no'">
                <select :rules="isRequired" v-model="parametros2[i.num_parametro]"
                        :style="{ border: '1px solid black', 'border-radius': '5px' }" required>
                  <option v-for="(j, indexj) in jsonmodal.parameters.ynselect" :key="indexj">{{ j.nombre }}</option>
                </select>
              </div>
              <div id="v-model-select" class="demo" v-if="JSON.parse(i.data).options=='get_name_modalidad_contrato'">
                <select :rules="isRequired" v-model="parametros2[i.num_parametro]"
                        :style="{ border: '1px solid black', 'border-radius': '5px' }" required>
                  <option v-for="(j, indexj) in jsonmodal.parameters.modselect" :key="indexj">{{ j.nombre }}</option>
                </select>
              </div>
              <div id="v-model-select" class="demo" v-if="JSON.parse(i.data).options=='get_municipio'">
                <select :rules="isRequired" v-model="parametros2[i.num_parametro]"
                        :style="{ border: '1px solid black', 'border-radius': '5px' }" required>
                  <option v-for="(j, indexj) in jsonmodal.parameters.muniselect" :key="indexj">{{ j.Name }}</option>
                </select>
              </div>
              <div id="v-model-select" class="demo" v-if="JSON.parse(i.data).options=='get_name_bodega'">
                <select :rules="isRequired" v-model="parametros2[i.num_parametro]"
                        :style="{ border: '1px solid black', 'border-radius': '5px' }" required>
                  <option v-for="(j, indexj) in jsonmodal.parameters.bodselect" :key="indexj">{{ j.WhsName }}</option>
                </select>
              </div>
              <div id="v-model-select" class="demo" v-if="JSON.parse(i.data).options=='get_name_servicio'">
                <select :rules="isRequired" v-model="parametros2[i.num_parametro]"
                        :style="{ border: '1px solid black', 'border-radius': '5px' }" required>
                  <option v-for="(j, indexj) in jsonmodal.parameters.servselect" :key="indexj">{{ j.nombre }}</option>
                </select>
              </div>
              <div id="v-model-select" class="demo" v-if="JSON.parse(i.data).options=='get_departamento'">
                <select :rules="isRequired" v-model="parametros2[i.num_parametro]"
                        :style="{ border: '1px solid black', 'border-radius': '5px' }" required>
                  <option v-for="(j, indexj) in jsonmodal.parameters.deptselect" :key="indexj">{{ j.NAME }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
<!--        Valida input tipo text-->
        <div class="p-field" v-if="i.type=='text'">
          <div class="p-grid">
            <div class="p-col-2">
              <label :for=" i.name_input[i.num_parametro] "><strong>{{ i.label }}:</strong></label>
            </div>
            <div class="p-col-1">
            </div>
            <div class="p-col-9">
              <div id="v-model-select" class="demo">
                <input :rules="isRequired" v-model="parametros2[i.num_parametro]" placeholder="Escribir aqui" required/>
              </div>
            </div>
          </div>
        </div>

<!--        Valida input tipo radio-->
        <div class="p-field" v-if="i.type=='radio'">
          <div class="p-grid">
            <div class="p-col-2">
              <label :for=" i.name_input[i.num_parametro] "><strong>Mensual:</strong></label>
            </div>
            <div class="p-col-1">
            </div>
            <div class="p-col-9">
              <div id="v-model-select" class="demo">
                <input :rules="isRequired" v-model="parametros2[i.num_parametro]" type="radio" value="SI" id="radio"/>
                <label for="radio"> Sí</label>
              </div>
            </div>
          </div>
        </div>
      </div>
<!--      Boton excel-->
      <div class="p-field">
        <div class="p-grid">
          <div class="p-col-2">
            <label for="" class=""><strong>Exportar a Excel: </strong></label>
          </div>
          <div class="p-col-1">
          </div>
          <div class="p-col-9">
            <div class="p-formgroup-inline">
              <div class="p-field-radiobutton">
                <RadioButton id="downExcelsi" name="downExcel" value="SI" v-model="excel"/>
                <label for="downExcel">Sí</label>
                <RadioButton id="downExcelno" name="downExcel" value="NO" v-model="excel"/>
                <label for="downExcelmo">No</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-field">
        <div class="p-grid">
          <div class="p-col-2">
          </div>
          <div class="p-col-7">
          </div>
          <div class="p-col-3">
            <br/>
            <Button label="Consultar" type="submit" autofocus align="center" style="float: right;"/>
          </div>
        </div>
      </div>
    </form>

    <div v-if="buscando==true">
      <p style="text-align: center;"><i class="pi pi-spin pi-spinner"
                                        style="fontSize: 1.2rem;  text-align: center;"></i></p>
      <p style="text-align: center; font-size: 15px;">Buscando...</p>
      <br/>
    </div>
    <DataTable v-if="datosparamostrar==true"
               removableSort
               sortMode="multiple"
               :value="conexion"
               responsiveLayout="scroll" scrollHeight="450px" :loading="loading"
               class="p-datatable-sm"
               paginator
               :rows="10"
               paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
               :rowsPerPageOptions="[10, 20, 50]"
               currentPageReportTemplate="Mostrando desde {first} a {last} de {totalRecords}"
    >
      <Column v-for="col of columns2" :field="col.field" :header="col.header" :key="col.field"
              :sortable="true"></Column>
      <template #paginatorLeft>
      </template>
      <template #paginatorRight>
      </template>
    </DataTable>

    <div class="p-grid">
      <div class="p-col-1">
      </div>
      <div class="p-col-10">
        <div class="container" v-if="datosparamostrar==false && yaconsult==true && servererror==false">
          <p style="text-align: center; font-size: 18px;">No hay datos para mostrar</p>
        </div>
        <div class="container" v-if="datosparamostrar==false && yaconsult==true && servererror==true">
          <p style="text-align: center; font-size: 18px;">Error de servidor</p>
        </div>
      </div>
      <div class="p-col-1">
      </div>
    </div>
  </Dialog>
</template>

<script>
import { onMounted, ref } from 'vue'
import RadioButton from 'primevue/radiobutton'
import microsap from '../../../../services/FarmConsulFecha/sap'

export default {
  components: {
    RadioButton
  },
  setup (props) {
    var display = ref(false)
    var resultado = ref({})
    var fechs = ref([])
    var selected = ref(null)
    var downExcel = ref('no')
    var conexion = ref(null)
    var columns2 = ref([])
    var parametros2 = ref([])
    var datosparamostrar = ref(false)
    var buscando = ref(false)
    var yaconsult = ref(false)
    var servererror = ref(false)
    var excel = ref('NO')

    onMounted(() => {
    })

    const fechasfunc = (namequery) => {
      columns2.value = []
      datosparamostrar.value = false
      yaconsult.value = false
      buscando.value = true
      for (let i = 0; i < parametros2.value.length; i++) {
        if (typeof parametros2.value[i] === 'object') {
          parametros2.value[i] = String(parametros2.value[i])
        }
      }
      parametros2.value[10] = namequery
      microsap(parametros2.value).then((response) => {
        if (response.data === 'cero') {
          buscando.value = false
          datosparamostrar.value = false
          yaconsult.value = true
        } else if (response.data === 'error') {
          buscando.value = false
          datosparamostrar.value = false
          yaconsult.value = true
          this.servererror = true
        } else {
          datosparamostrar.value = true
          yaconsult.value = true
          var data = []
          var columm = []
          for (var i in response.data) {
            var item = {}
            for (var key in response.data[i]) {
              response.data[i].key = key.replace(/\./g, '')
              item[key.replace(/\./g, '')] = response.data[i][key]
              if (i === '0') {
                columm.push({ field: key.replace(/\./g, ''), header: key.replace(/\./g, '') })
              }
            }
            buscando.value = false
            columns2.value = columm
            data.push(item)
          }
          conexion.value = data
        }
      }).catch(err => {
        console.error(err.message)
      })
    }
    const doSomething = () => {
      fechs.value = []
      parametros2.value = []
      conexion.value = []
      columns2.value = []
      datosparamostrar.value = false
      yaconsult.value = false
      servererror.value = false
      excel.value = 'NO'
      buscando.value = false
    }

    return {
      downExcel,
      display,
      fechs,
      conexion,
      columns2,
      parametros2,
      fechasfunc,
      selected,
      doSomething,
      datosparamostrar,
      yaconsult,
      servererror,
      resultado,
      excel,
      buscando
    }
  },
  props: ['jsonmodal'],
  methods: {
    // Validator function
    onSubmit (values) {
    },
    isRequired (value) {
      return value ? true : 'This field is required'
    },
    contact (jsonm) {
      this.fechasfunc(jsonm)
    }
  }

}
</script>

<style scoped lang="scss">
a:hover {
  // background: green;
  font-size: 16.3px;
  color: #14286d;
  cursor: pointer;
}

a {
  color: #1c3faa;
  font-weight: 400;
}
</style>
